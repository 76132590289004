import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const ThanksContainer = styled.section`
    height: 85vh;
    ${tw`flex items-center justify-center flex-wrap`};
`;

const ThanksDiv = styled.div`
    ${tw`px-8 sm:px-10 text-center`}
`;

const ThanksText = tw.h1`text-6xl text-black text-center w-full mb-8`;

const Thanks = () => {
    return (
        <Layout>
            <SEO title="Thanks" />
            <ThanksContainer>
                <ThanksDiv>
                    <ThanksText>Thanks for signing up!</ThanksText>
                    <h4>Upon successfuly payment, you should receive an email from JT Bains shortly.</h4>
                </ThanksDiv>
            </ThanksContainer>
        </Layout>
    );
};

export default Thanks;
